import { useTrace } from '@local/web-design-system';
import { Grid } from '@mui/material';
import { latLng } from 'leaflet';
import { MapContainer, ScaleControl } from 'react-leaflet';
import { useSelector } from 'react-redux';
import store from 'store';
import { projectState } from 'state-domains/domain';
import React from 'react';

import { isNavDrawerOpen as isDrawerOpenSelector, isSidePanelOpen } from '../../../state/Drawer';

import { AddMapLayers } from './AddMapLayers';
import { useStyles } from './DrillholeMap.styles';
import { DrillholeMapProps, MapItemProps, WrappedLongitudeDict } from './DrillholeMap.types';
import { AutoWrapMarkers, DrillholeMarkers } from './DrillholeMarkers';
import { MapBoundaries } from './MapBoundaries';
import { CreateBoundaryControlPanel } from './MapBoundaryControls/MapBoundaryControls';
import { MapGridPreview } from './MapGridPreview';
import {
    findDrillholeCoordinatesBounds,
    MAX_BOUNDS,
    drillholeHasValidPosition,
    ZOOM_LEVEL,
    convertLatLngBoundsToMapItems,
} from './MapUtils';

export const STORE_MAP_LABEL_STATE = 'map_label_state';
export const STORE_MAP_BOUNDARY_STATE = 'map_boundary_state';
export const STORE_SELECTED_BOUNDARY_SYSTEM = 'project_boundary_selected_system';
export const STORE_BOUNDARY_SHOW_DETAILS = 'project_boundary_show_details';
export const STORE_BOUNDARY_DEGREE_BUTTON = 'project_boundary_show_degrees';

export function DrillholeMap({
    id,
    drillholes,
    zoom,
    mapBounds,
    onExpand = undefined,
    hideMapMarkerPopup = false,
    projectBoundaries = [],
    overviewMap = false,
    locationMap = false,
    propertiesMap = false,
    onMoveCallback = undefined,
    onMarkerSelectCallback = undefined,
    disableMap = false,
    preferCanvas = false,
    editBoundaries = false,
    previewGrid = false,
    currentProject,
    previewGridData,
}: Readonly<DrillholeMapProps>) {
    const [mapReady, setMapReady] = React.useState<boolean>(false);
    const [map, setMap] = React.useState<any>(null);

    const [showLabels, setShowLabels] = React.useState<boolean>(
        store.get(STORE_MAP_LABEL_STATE) ?? false,
    );
    const [showBounds, setShowBounds] = React.useState<boolean>(
        editBoundaries ? true : (store.get(STORE_MAP_BOUNDARY_STATE) ?? false),
    );
    const [isSatellite, setIsSatellite] = React.useState<boolean>(false);
    const stopFitBounds = React.useRef<boolean>(false);
    const [visibleLocationBounds, setVisibleLocationBounds] =
        React.useState<ReturnType<typeof findDrillholeCoordinatesBounds>>();

    const {
        selectors: { mapLayer },
    } = projectState;

    const drawerState = useSelector(isDrawerOpenSelector);
    const propertiesState = useSelector(isSidePanelOpen);
    const layer = useSelector(mapLayer);
    const { classes } = useStyles();
    const defaultPosition = mapBounds ? mapBounds.getCenter() : latLng(0, 0);
    const defaultZoom = zoom ?? ZOOM_LEVEL.MIN;

    const applyTrace = useTrace('drillhole-map');
    const [drillholesWithLatLng, setDrillholesWithLatLng] = React.useState(
        drillholes?.filter((drillhole: MapItemProps) =>
            drillholeHasValidPosition(drillhole.wgs84 ?? {}),
        ) ?? [{}],
    );

    // Dictionary of drillholeId, "wrapped" long pairs. That is updated on map zoom or movement
    // This moves the markers such that they're visible as the map infintely pans
    const [wrappedLongitudes, setWrappedLongitudes] = React.useState<WrappedLongitudeDict>({});

    React.useEffect(() => {
        const dhsWithLatLong = drillholes?.filter((drillhole: MapItemProps) =>
            drillholeHasValidPosition(drillhole.wgs84 ?? {}),
        ) ?? [{}];
        setDrillholesWithLatLng(dhsWithLatLong);

        const wrappedDhLongitudes: WrappedLongitudeDict = {};
        dhsWithLatLong.forEach((drillhole: MapItemProps) => {
            wrappedDhLongitudes[drillhole.id] = drillhole.wgs84.easting ?? 0;
        });

        setWrappedLongitudes(wrappedDhLongitudes);
        setVisibleLocationBounds(
            findDrillholeCoordinatesBounds(
                mapBounds ? convertLatLngBoundsToMapItems(mapBounds) : dhsWithLatLong,
            ),
        );
    }, [drillholes, mapBounds]);

    React.useEffect(() => {
        if (mapReady && map && visibleLocationBounds && drillholes.length !== 0) {
            if ((!overviewMap || !mapBounds) && !stopFitBounds.current) {
                map.fitBounds(visibleLocationBounds, {
                    maxZoom: locationMap ? ZOOM_LEVEL.JUMP : ZOOM_LEVEL.MED,
                });
            } else {
                stopFitBounds.current = false;
            }
        }
    }, [mapReady, map, visibleLocationBounds, projectBoundaries, overviewMap, mapBounds]);

    React.useEffect(() => {
        setTimeout(() => {
            if (mapReady && map) {
                map.invalidateSize();
            }
        }, 500);
    }, [drawerState, propertiesState, mapReady, map]);

    React.useEffect(() => {
        if (mapReady && map && propertiesMap) {
            if (onExpand) {
                map.on('click', () => {
                    onExpand();
                });
            }
            if (!disableMap) {
                map.dragging.enable();
            } else {
                map.dragging.disable();
            }
        }
    }, [mapReady, map, propertiesMap, onExpand, disableMap]);

    React.useEffect(() => {
        if (mapReady && map && !propertiesMap) {
            map.on('moveend', (_ev: any) => {
                if (onMoveCallback) {
                    onMoveCallback(map.getBounds(), map.getZoom());
                }
            });
        }
    }, [mapReady, map]);

    const onMapReady = React.useCallback(() => {
        setMapReady(true);
    }, []);

    React.useEffect(() => {
        if (map && mapReady) {
            const control = document.getElementById('mapControlContainer');
            const zoomControls = Array.from(
                document.getElementsByClassName(
                    'leaflet-control-zoom',
                ) as HTMLCollectionOf<HTMLElement>,
            );

            if (disableMap) {
                map.dragging.disable();
                map.touchZoom.disable();
                map.scrollWheelZoom.disable();
                map.boxZoom.disable();
                map.keyboard.disable();
                if (map.tap) map.tap.disable();
                if (control) control.style.display = 'none';
                if (zoomControls.length > 1) zoomControls[1].style.display = 'none';
            } else if (!propertiesMap) {
                map.dragging.enable();
                map.touchZoom.enable();
                map.scrollWheelZoom.enable();
                map.boxZoom.enable();
                map.keyboard.enable();
                if (map.tap) map.tap.enable();
                if (control) control.style.display = 'block';
                if (zoomControls.length > 1) zoomControls[1].style.display = 'block';
            }
        }
    }, [disableMap, mapReady, map, previewGrid]);

    const hideMarkerPopup = propertiesMap || hideMapMarkerPopup;
    let panelStyle = locationMap ? classes.locationMapContainer : classes.panelMapContainer;
    const largeMapStyle = overviewMap ? classes.overviewMap : classes.mapContainer;
    if (onExpand === undefined && propertiesMap)
        panelStyle = `${panelStyle} ${classes.nonInteraction}`;
    return (
        <Grid container height="100%" direction="row" wrap="nowrap" {...applyTrace()}>
            <link
                rel="stylesheet"
                href="//cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.min.css"
            />
            <link
                rel="stylesheet"
                href="//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css"
            />
            <Grid item style={{ width: '100%', height: '100%' }} {...applyTrace('container')}>
                <MapContainer
                    id={id}
                    ref={setMap}
                    whenReady={onMapReady}
                    scrollWheelZoom={!propertiesMap}
                    className={locationMap || propertiesMap ? panelStyle : largeMapStyle}
                    minZoom={ZOOM_LEVEL.MIN}
                    maxZoom={ZOOM_LEVEL.MAX}
                    maxBounds={MAX_BOUNDS}
                    maxBoundsViscosity={1} // stop bouncing when panning past bounds
                    worldCopyJump
                    center={defaultPosition}
                    zoom={defaultZoom}
                    bounds={visibleLocationBounds}
                    attributionControl={false}
                    zoomControl={false} // Gets added in mapReady useEffect
                    preferCanvas={preferCanvas}
                    doubleClickZoom={false}
                >
                    {mapReady && map && (
                        <>
                            {editBoundaries && (
                                <CreateBoundaryControlPanel
                                    map={map}
                                    originalBoundaryData={projectBoundaries}
                                    currentProject={currentProject}
                                />
                            )}
                            {previewGrid && (
                                <MapGridPreview
                                    map={map}
                                    data={previewGridData}
                                    isSatellite={isSatellite}
                                />
                            )}
                            <MapBoundaries
                                map={map}
                                projectBoundaries={projectBoundaries}
                                currentProject={currentProject}
                                showBounds={showBounds}
                                showControls={editBoundaries}
                                disabled={disableMap}
                            />
                            <AddMapLayers
                                hideLayerSelection={locationMap || propertiesMap}
                                setShowLabels={setShowLabels}
                                setShowBounds={setShowBounds}
                                stopFitBounds={stopFitBounds}
                                showLayersOnly={editBoundaries || previewGrid}
                                previewGrid={previewGrid}
                                setIsSatellite={setIsSatellite}
                            />
                        </>
                    )}
                    <DrillholeMarkers
                        {...{
                            drillholesWithLatLng,
                            wrappedLongitudes,
                            setWrappedLongitudes,
                            hideMapMarkerPopup: hideMarkerPopup,
                            currentLayer: layer,
                            locationMap,
                            overviewMap,
                            onExpand,
                            showLabels: showLabels && !propertiesMap,
                            onSelectCallback: onMarkerSelectCallback,
                            disableMap,
                            propertiesMap,
                        }}
                    />
                    <AutoWrapMarkers
                        drillholesWithLatLng={drillholesWithLatLng}
                        setWrappedLongitudes={setWrappedLongitudes}
                    />
                    {!locationMap && (overviewMap || !propertiesMap) && (
                        <ScaleControl imperial metric />
                    )}
                </MapContainer>
            </Grid>
        </Grid>
    );
}
